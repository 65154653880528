import { render, staticRenderFns } from "./VideoSlide.vue?vue&type=template&id=587f3e86&scoped=true&"
import script from "./VideoSlide.vue?vue&type=script&lang=js&"
export * from "./VideoSlide.vue?vue&type=script&lang=js&"
import style0 from "./VideoSlide.vue?vue&type=style&index=0&id=587f3e86&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "587f3e86",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {YoutubeVideo: require('/var/www/html/spa-website/components/YoutubeVideo.vue').default,Markdown: require('/var/www/html/spa-website/components/Markdown.vue').default,IconNext: require('/var/www/html/spa-website/components/icons/IconNext.vue').default})
